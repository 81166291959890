<template>
  <div>
    <div class="mb-2">
      <a href="#" @click.prevent="$router.go(-1)" class="">
        <i class="bi bi-arrow-left-short icon-sm "></i> Go back
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <div class="card shadow-sm rounded">
          <div class="card-body">
            <h4 class="font-weight-bold mb-2">{{ program.program_name }}</h4>
            <span v-if="program.former_name" class="form-text text-muted mb-3"
              >Former Name: {{ program.former_name }}</span
            >
            <p class="mb-0">
              Program ID: <strong>{{ program.id }}</strong>
            </p>
            <p class="mb-0">
              Discipline Code: <strong> {{ program.discipline_code }} </strong>
            </p>
            <p class="mb-0">
              Level Code: <strong>{{ program.level_code }}</strong>
            </p>
          </div>
        </div>

        <ProgramReferencesTab :program="program"></ProgramReferencesTab>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <ProgramAreasOfEvaluationTab
          :program="program"
        ></ProgramAreasOfEvaluationTab>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramReferencesTab from './ProgramReferencesTab.vue';
import ProgramAreasOfEvaluationTab from './ProgramAreasOfEvaluationTab.vue';

export default {
  name: 'ProgramDetails',
  components: {
    ProgramReferencesTab,
    ProgramAreasOfEvaluationTab,
  },
  props: ['program'],
  methods: {
    previousRoute() {
      this.$router.go(-1);
    },
  },
};
</script>
