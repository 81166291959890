<template>
  <div class="mt-4">
    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="card-title">
          <p class="font-weight-bold">CMO References</p>

          <div v-if="loader" class="d-flex align-items-center mb-3">
            <strong>Loading areas...</strong>
            <div
              class="spinner-border spinner-border-sm ml-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>

          <span v-if="!references.length && !loader"
            >No files uploaded yet.</span
          >

          <ol class="pl-3">
            <li v-for="(ref, index) in references" :key="ref.code">
              <div>
                <a href="#" @click.prevent="openUrl(ref.file)">
                  <i class="bi bi-file-pdf"></i> {{ ref.code }} :
                  {{ ref.title }}
                </a>
                <button
                  class="btn btn-link text-danger"
                  @click.prevent="deleteReference(index)"
                >
                  <i class="bi bi-trash-fill"></i>
                </button>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="card-title">
          <strong>Upload CMO Reference</strong>
        </div>
        <form @submit.prevent="addReference">
          <div class="form-group">
            <label for="">Code</label>
            <div class="form-inline">
              <input
                type="text"
                v-model="reference.code"
                class="form-control mr-2"
                placeholder="Ex. CMO No. 15"
                required
              />
              s.
              <input
                type="text"
                v-model="reference.series"
                class="form-control ml-2"
                placeholder="Year"
                maxlength="4"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="">Title</label>
            <textarea
              v-model="reference.title"
              type="text"
              class="form-control"
              required
            ></textarea>
          </div>

          <div class="form-group">
            <label for="">Reference File</label>
            <input
              id="program-psg-file-input"
              type="file"
              class="form-control-file"
              accept="application/pdf"
              @change="handleFileInput"
              required
            />
          </div>

          <button
            type="submit"
            id="add-reference-btn"
            class="btn btn-primary mt-2"
          >
            Upload
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramReferencesTab',
  props: ['program'],
  data() {
    return {
      prefix: 'https://drive.google.com/file/d/',
      reference: new Form({
        code: '',
        series: '',
        title: '',
        file: '',
      }),
      references: [],
      loader: false,
    };
  },
  methods: {
    handleFileInput(event) {
      this.reference.file = event.target.files[0];
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },

    getReferences(option) {
      this.loader = option.loader;
      axios
        .get('api/programs/references/' + this.program.id)
        .then((res) => {
          this.references = res.data;
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;
          toastr.error('Oops! Unable to retrieve files.', 'Server Error');
        });
    },

    addReference() {
      let btn = document.getElementById('add-reference-btn');
      btn.disabled = true;
      btn.innerHTML = 'Uploading...';

      let fd = new FormData();

      fd.append('file', this.reference.file);
      fd.append('title', this.reference.title);
      fd.append('code', `${this.reference.code} s. ${this.reference.series}`);
      fd.append('id', this.program.id);

      axios
        .post('api/programs/reference', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          this.getReferences({ loader: false });
          this.reference.reset();
          document.getElementById('program-psg-file-input').value = '';
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          toastr.error('Something went wrong.', 'Unable to upload file');
        });
    },

    deleteReference(index) {
      if (
        confirm(
          'WARNING: This action cannot be undone. Are you sure you want to delete the file?'
        )
      ) {
        axios
          .delete('api/programs/references/' + this.program.id, {
            params: {
              index: index,
            },
          })
          .then((res) => {
            toastr.success(res.data.message, 'Info');
            this.getReferences({ loader: false });
          })
          .catch((err) => {
            toastr.error('Oops! Unable to delete file.', 'Server Error');
          });
      }

      return;
    },
  },
  mounted() {
    this.getReferences({ loader: true });
  },
};
</script>
