<template>
  <div class="">
    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="card-title">
          <div class="d-flex justify-content-between mb-3 align-items-center">
            <strong>Areas for Evaluation</strong>
            <button class="btn btn-primary" @click.prevent="openAddAreasModal">
              <i class="bi bi-plus-lg mr-1"></i> Add Areas for Evaluation
            </button>
          </div>
        </div>

        <div v-if="loader" class="d-flex align-items-center mb-3">
          <strong>Loading areas...</strong>
          <div
            class="spinner-border spinner-border-sm ml-auto"
            role="status"
            aria-hidden="true"
          ></div>
        </div>

        <p v-if="!programAreas.length && !loader">
          No areas have been added yet.
        </p>

        <table
          v-if="processedProgramAreas.length"
          class="table table-sm table-bordered"
        >
          <thead>
            <tr>
              <th>CMO REQUIREMENTS</th>
              <th>ADDED ON</th>
              <th>BY</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(areaData, index) in processedProgramAreas" :key="index">
              <template v-if="areaData.isGroupHeader">
                <td colspan="6" class="text-uppercase">
                  <strong
                    >{{ getAlphabeticalCharacter(areaData.headerIndex) }}.
                    {{ areaData.area.name }}</strong
                  >
                </td>
              </template>
              <template v-else>
                <td>
                  <p style="white-space: pre-line" class="mb-0">
                    {{ areaData.indexInGroup + 1 }}.
                    {{ areaData.area.cmo_requirements }}
                  </p>
                </td>
                <td>{{ areaData.area.created_at | shortDate }}</td>
                <td>{{ areaData.area.added_by }}</td>
                <td>
                  <div class="btn-group">
                    <button
                      class="btn btn-outline-secondary btn-sm"
                      @click.prevent="openUpdateModal(areaData.area)"
                    >
                      <i class="bi bi-pencil-fill text-success"></i>
                    </button>

                    <button
                      class="btn btn-outline-secondary btn-sm"
                      @click.prevent="deleteProgramArea(areaData.area)"
                    >
                      <i class="bi bi-trash-fill text-danger"></i>
                    </button>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="add-areas-modal" data-backdrop="static">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <form
            @submit.prevent="
              updateMode ? updateProgramArea() : addProgramArea()
            "
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ updateMode ? 'Update Area' : 'Add Areas for Evaluation' }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="form-group">
                  <label for="">Area</label>
                  <select
                    name=""
                    class="form-control col-auto"
                    id=""
                    v-model="area.area_id"
                    required
                  >
                    <option value="" disabled selected hidden>Choose</option>
                    <option v-for="a in areas" :key="a.id" :value="a.id">
                      {{ a.name }}
                    </option>
                  </select>
                </div>

                <div class="form-inline mb-3">
                  <button
                    class="btn btn-outline-secondary btn-sm text-dark mr-2"
                    @click.prevent="isAddingNewArea = true"
                  >
                    <i class="bi bi-plus-lg"></i>
                  </button>
                  <span>Add new area</span>
                </div>

                <div v-if="isAddingNewArea" class="form-inline mb-3">
                  <input
                    type="search"
                    v-model="newArea"
                    class="form-control col-4"
                    placeholder=""
                  />
                  <button
                    id="add-new-area-btn"
                    class="btn btn-success ml-2"
                    @click.prevent="addNewArea"
                  >
                    Save
                  </button>
                </div>

                <div class="form-group">
                  <label for="">CMO Specific Requirements</label>

                  <ol class="pl-3">
                    <li
                      v-for="(req, index) in area.reqs"
                      class="mb-3"
                      :key="index"
                    >
                      <input
                        v-model="req.desc"
                        type="search"
                        class="form-control"
                        required
                      />
                    </li>
                  </ol>
                </div>

                <div v-if="!updateMode" class="form-inline mb-3">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-outline-secondary text-dark btn-sm"
                      @click.prevent="addReq"
                    >
                      <i class="bi bi-plus icon-sm "></i>
                    </button>
                    <button
                      class="btn btn-outline-secondary text-dark btn-sm"
                      @click.prevent="removeReq"
                      :disabled="area.reqs.length == 1"
                    >
                      <i class="bi bi-dash icon-sm"></i>
                    </button>
                  </div>

                  <span>Click to add or remove</span>
                </div>

                <p>
                  <i class="bi bi-exclamation-circle-fill mr-2"></i>
                  <strong>Please note</strong>: Each requirement corresponds to
                  a checkbox on the evaluation form.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link text-dark"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="add-program-area-btn"
                type="submit"
                class="btn btn-primary"
                :disabled="btnLoader"
              >
                <span v-if="!btnLoader">{{
                  updateMode ? 'Update' : 'Add'
                }}</span>
                <div v-else class="spinner-border spinner-border-sm"></div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramAreasOfEvaluationTab',
  props: ['program'],
  data() {
    return {
      selectedAreas: [],
      area: new Form({
        id: '',
        area_id: '',
        reqs: [{ desc: '' }],
        program_id: this.program.id,
      }),
      programAreas: [],
      areas: [],
      loader: false,
      isAddingNewArea: false,
      newArea: '',
      updateMode: false,
      defaultInputBox: 0,
      btnLoader: false,
    };
  },
  methods: {
    openUpdateModal(area) {
      $('#add-areas-modal').modal('show');
      this.updateMode = true;
      this.area.id = area.id;
      this.area.area_id = area.area_id;
      this.area.reqs[this.defaultInputBox].desc = area.cmo_requirements;
    },

    updateProgramArea() {
      this.btnLoader = true;

      axios
        .put('api/programs/areas/' + this.area.id, this.area)
        .then((res) => {
          this.area.reqs[this.defaultInputBox].desc = '';
          $('#add-areas-modal').modal('hide');
          toastr.success(res.data.message, 'Info');
          this.area.reset();
          this.getProgramAreas({ loader: false });
          this.updateMode = false;
          this.btnLoader = false;
        })
        .catch((err) => {
          this.updateMode = false;
          this.btnLoader = false;
          toastr.error('Oops! Unable to update area', 'Server Error');
        });
    },

    addNewArea() {
      if (this.newArea == '') {
        toastr.error('Please input the new area.', 'Error');
        return;
      }

      let btn = document.getElementById('add-new-area-btn');
      btn.disabled = true;
      btn.innerHTML = 'Saving...';

      axios
        .post('api/areas', {
          newArea: this.newArea,
        })
        .then((res) => {
          toastr.success(res.data.message, 'Info');
          this.isAddingNewArea = false;
          this.newArea = '';
          this.getAreas();
        })
        .catch((err) => {
          toastr.error('Oops! Unable to add new area.', 'Server Error');
          this.isAddingNewArea = false;
        });
    },
    openAddAreasModal() {
      $('#add-areas-modal').modal('show');
      this.isAddingNewArea = false;
      this.updateMode = false;
      this.area.reset();
      this.area.reqs = [];
      this.area.reqs.push({
        desc: '',
      });
    },

    addProgramArea() {
      this.btnLoader = true;
      axios
        .post('api/programs/areas', this.area)
        .then((res) => {
          this.area.reset();
          this.getProgramAreas({ loader: false });
          this.btnLoader = false;
          $('#add-areas-modal').modal('hide');
        })
        .catch((err) => {
          this.btnLoader = false;
        });
    },
    deleteProgramArea(area) {
      if (
        !confirm(
          'This action cannot be undone. Are you sure you want to proceed?'
        )
      )
        return;

      axios
        .delete('api/programs/areas/' + area.id, area)
        .then((res) => {
          this.getProgramAreas({ loader: false });
        })
        .catch((err) => {
          toastr.error('Unable to delete area.', 'Oops! Something went wrong.');
        });
    },
    getProgramAreas(option) {
      this.loader = option.loader;
      axios
        .get('api/programs/areas/' + this.program.id)
        .then((res) => {
          this.programAreas = res.data;
          this.loader = false;
        })
        .catch((err) => {
          toastr.error('Unable to fetch areas.', 'Oops! Something went wrong.');
          this.loader = false;
        });
    },
    getAreas() {
      axios
        .get('api/areas')
        .then((res) => {
          this.areas = res.data;
        })
        .catch((err) => {
          toastr.error('Unable to retrieve areas.', 'Server Error');
        });
    },
    removeReq() {
      this.area.reqs.pop();
    },
    addReq() {
      this.area.reqs.push({
        desc: '',
      });
    },
    getAlphabeticalCharacter(index) {
      return String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    },
  },
  computed: {
    processedProgramAreas() {
      let groupedAreas = {};

      this.programAreas.forEach((area) => {
        const areaName = area.name;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        groupedAreas[areaName].push(area);
      });

      let result = [];
      let headerIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
          });
        });
      }

      return result;
    },
  },
  mounted() {
    this.getAreas();
    this.getProgramAreas({ loader: true });
  },
};
</script>
